import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IPermission } from '@app/core/models/permission.model';
import { Permissions } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private _userPermissions$ = new BehaviorSubject<string[]>([]);

  clearUserPermissions() {
    this._userPermissions$.next([]);
  }

  loadCurrentUserPermissions() {
    firstValueFrom(this._loadCurrentUserPermissions()).then();
  }

  _loadCurrentUserPermissions(): Observable<string[]> {
    const permissions = JSON.parse(
      localStorage.getItem('PERMISSIONS'),
    ) as IPermission[];
    if (!permissions) {
      this._userPermissions$.next([]);
      return of([]);
    }
    return of(permissions.flat()).pipe(
      map((permissions) =>
        permissions.map((permission) => permission.shareKeyNormalize),
      ),
      tap((response) => {
        this._userPermissions$.next(response);
      }),
      catchError(() => {
        this._userPermissions$.next([]);
        return [];
      }),
    );
  }

  userHasPermission(
    permissionKey: string | string[] | undefined,
    permissionType?: 'AND' | 'OR',
  ): Observable<boolean> {
    if (!this._userPermissions$.getValue()?.length)
      this.loadCurrentUserPermissions();
    if (permissionKey === undefined) return of(false);
    return this.checkPermissions(permissionKey, permissionType);
  }

  private checkPermissions(
    permissionKey: string | string[],
    permissionType?: 'AND' | 'OR',
  ): Observable<boolean> {
    return this._userPermissions$.pipe(
      map((permissions) => {
        if (permissions.includes(Permissions.SuperAdmin)) {
          return true;
        }

        if (Array.isArray(permissionKey)) {
          if (permissionType === 'AND')
            return permissionKey.every((key) => permissions.includes(key));
          if (permissionType === 'OR')
            return permissionKey.some((key) => permissions.includes(key));
        } else {
          return permissions.includes(permissionKey);
        }
      }),
    );
  }
}
